<template>
  <div class="index">
    <div class="head">
      <label>生命安全应急接警中心</label>
      <div class="time">{{ date }} {{ week }}</div>
      <div class="loginOut" @click="login_out()">
        <i class="el-icon-switch-button"></i>
      </div>
    </div>
    <div class="left">
      <div class="head">
        <div class="text">今日警情</div>
      </div>
      <div class="number">
        <div class="item" style="margin-left: 1.95vw">
          <div class="img">
            <div class="num">{{ userCount }}</div>
          </div>
          <div class="text">用户数</div>
        </div>
        <div class="item">
          <div class="img">
            <div class="num">{{ callPoliceCount }}</div>
          </div>
          <div class="text">报警数</div>
        </div>
        <div class="item">
          <div class="img">
            <div class="num">{{ accessCount }}</div>
          </div>
          <div class="text">接入量</div>
        </div>
      </div>

      <div class="datatype">
        <div :class="dataType == 0 ? 'select' : ''" @click="dataSelect(0)">
          日
        </div>
        <div :class="dataType == 1 ? 'select' : ''" @click="dataSelect(1)">
          周
        </div>
        <div :class="dataType == 2 ? 'select' : ''" @click="dataSelect(2)">
          月
        </div>
        <div :class="dataType == 3 ? 'select' : ''" @click="dataSelect(3)">
          年
        </div>
      </div>
      <div class="echart-img">
        <line-chart ref="barChartTo" />
        <div class="back"></div>
      </div>
      <div class="head-1">
        <div :class="scanType == 0 ? 'select' : ''" @click="scanSelect(0)">
          今日警情记录
        </div>
        <div :class="scanType == 1 ? 'select' : ''" @click="scanSelect(1)">
          本周警情记录
        </div>
        <!--  <div class="text-1"></div>
        <div class="text-2"></div> -->
      </div>
      <div class="record">
        <div
          class="row"
          v-for="(item, index) in dataList"
          :key="item.scanId"
          @click="itemRowClick(item)"
        >
          <div class="num">
            <span v-if="item.isAccess === 0" style="color: red">
              {{ index + 1 }}
            </span>
            <span v-if="item.isAccess === 1">
              {{ index + 1 }}
            </span>
          </div>
          <div class="text">
            <span v-if="item.isAccess === 0" style="color: red">
              {{ item.name }}-{{ item.phone }}-{{ item.address.substring(0,14) + "..." }}发生预警
            </span>
            <span v-if="item.isAccess === 1">
              {{ item.name }}-{{ item.phone }}-{{ item.address.substring(0,14) + "..." }}发生预警
            </span>
          </div>
        </div>
      </div>
    </div>

    <div id="container" class="right"></div>





    <div class="mask" v-if="isMask" @click="isMask = false">
        <div class="mask_bg" @click.stop>
            <img src="../../assets/images/close.png" alt="" style="width: 18px;height: 18px;margin-left: 92%;margin-top: 2%;cursor: pointer;" @click="isMask = false">
            <div class="mask_title">应急人员派发</div>

            <div class="mask-content">
                <div>
                    <div></div>
                    <div>姓名</div>
                    <div>电话</div>
                    <div>距离</div>
                </div>
                <div style="" class="list">
                    <div  v-for="(item,index) in maskList" :key="index"  class="list-item">
                        <div style="display: flex;justify-content: space-around;">
                            <img :src="!item.isSelected ? require(`../../assets/images/no.png`) : require(`../../assets/images/yes.png`)" alt="" style="width: 22px;height: 22px;" @click="item.isSelected = !item.isSelected">
                            <img v-if="item.icon" :src="item.icon" alt="" style="width: 22px;height: 22px;">
                        </div>
                        <div>{{ item.name }}</div>
                        <div>{{ item.phone }}</div>
                        <div style="color: #00FCFF;">{{ item.distance }}KM</div>
                    </div>
                </div>
            </div>

            <div class="mask-footer">
                <div @click="selectAll()"> {{isAllSelected ? '反选' : '全选'}} </div>
                <div @click="distributeSMS">派发</div>
            </div>
        </div>
    </div>
  </div>
</template>
<script
  type="text/javascript"
  src="//webapi.amap.com/maps?v=2.0&key=fee76ae4b24121e30526f5e4d089f891&plugin=AMap.CitySearch"
></script>
<script>
import LineChart from "./../dashboard/LineChart.vue";
export default {
  components: {
    LineChart,
  },
  data() {
    return {
      dataType: 0,
      scanType: 0,
      map: {},
      htmlMarker: null,
      week: "",
      date: "",
      userCount: 0, //用户总数
      accessCount: 0, //接入数
      callPoliceCount: 0, //报警数
      dataList: [], //报警列表
      // 是否显示弹出层
      open: false,
      form: {},
      lng: 114.05,
      lat: 22.55,
      // lng: null,
      // lat: null,
      scanId: null,
      distributePhone: null,
      dataNewList: [],

      isMask:false, //弹窗状态
      maskList:[]
    };
  },
//   帮我写个计算属性,this.maskList中所有的isSelected为真返回真,否则返回假
  computed: {
    isAllSelected() {
      let isAll = true;
      this.maskList.forEach((item) => {
        if (!item.isSelected) {
          isAll = false;
        }
      });
      return isAll;
    },
  },
  created() {
    let date = new Date();
    var weekday = date.getDay();
    var weeks = new Array(
      "星期日",
      "星期一",
      "星期二",
      "星期三",
      "星期四",
      "星期五",
      "星期六"
    );
    this.week = weeks[weekday];
    let timer = setInterval(() => {
      //需要定时执行的代码
      this.date = this.parseTime(new Date());
      this.getList();
    }, 5000);
  },
  async mounted() {
    await this.IPInfo();
    this.map = new window.AMap.Map("container", {
      zoom: 16, //级别
      mapStyle: "amap://styles/darkblue", //设置地图的显示样式
      center: [this.lng, this.lat], //中心点坐标
    });

    window.formColse = (data) => {
      this.formColse();
    };
    window.accessUserClick = (data) => {
      this.accessUserClick(data);
    };
    window.handleDistribute = (data) => {
      this.handleDistribute(data);
    };
    // window.distributeSMS = () => {
    //   this.distributeSMS();
    // };
    window.flushLocation = (data) => {
      this.flushLocation(data);
    };
    this.getScanTimeList();
    this.getList();
  },
  methods: {
    selectAll(){
        if(this.isAllSelected){
            this.maskList.forEach((item) => {
            item.isSelected = false;
        });
        }else{
            this.maskList.forEach((item) => {
            item.isSelected = true;
        });
        }
      
    },
    async IPInfo() {
      await this.$api.lifescan.getIP().then((res) => {
        if (res.data.code === 200) {
          this.lng = res.data.data.lng;
          this.lat = res.data.data.lat;
          console.log(this.lng, "111111", this.lat);
        }
      });
    },
    itemRowClick(item) {
      this.setinfoWindow(item);
    },
    writeMarker(item) {
      var marker = new window.AMap.Marker({
        size: new window.AMap.Size(52, 68), // 图标尺寸
        imageSize: new window.AMap.Size(52, 68),
        position: new window.AMap.LngLat(item.longitude, item.latitude),
        offset: new window.AMap.Pixel(30, -85),
        anchor: "top-left",
        className: "a-marker",
        icon:
          item.isAccess === 0
            ? require("../../assets/images/marker-d.gif")
            : require("../../assets/images/marker.png"), // 添加 Icon 图标 URL
        title: item.address,
        id: "markerid" + item.scanId,
        extData: item.scanId,
        click: function () {
          alert("11213123");
        },
      });
      this.map.add(marker);
      marker.on("click", this.markerClick);
      return marker;
    },
    setMarker() {
      let that = this;
      that.dataList.forEach((item) => {
        var marker = that.writeMarker(item);
        item.marker = marker;
      });
    },
    scanSelect(item) {
      this.scanType = item;
      this.getList();
    },
    getList() {
      let that = this;
      let params = {};
      console.log(that.scanType);
      params.scanType = that.scanType;
      that.$api.lifescan.getScan(params).then((res) => {
        that.dataList = res.data.lifeScanWarningsList;

        if (that.dataNewList.length !== that.dataList.length) {
          this.setMarker();
        }
      
        that.dataNewList = that.dataList;
        //删除marker
        let isDelete = true;

        for (let i = 0; i < that.dataList.length; i++) {
          let row = that.dataList[i];

          res.data.lifeScanWarningsList.forEach((item) => {
            if (row.scanId === item.scanId) {
              isDelete = false;
              //如果状态不同，修改状态
              if (row.isAccess !== item.isAccess) {
                row.marker.setIcon(
                  item.isAccess === 0
                    ? require("../../assets/images/marker-d.gif")
                    : require("../../assets/images/marker.png")
                );
              }
              //赋值数据
              let marker = row.marker;
              that.dataList[i] = item;
              that.dataList[i].marker = marker;
            }

            if (row.idcard !== null) {
              /*  row.idcard = row.idcard.replace(
                /^(\d{6})\d{8}(.{4}$)/g, `$1${Array(9).join('*')}$2`
              ); */
              row.idcard = row.idcard.replace(
                /(.{3}).*(.{2})/,
                "$1*************$2"
              );
            }
          });

          if (isDelete) {
            this.map.remove(row.marker);
          }
        }

        //添加marker
        let isAdd = true;
        res.data.lifeScanWarningsList.forEach((item) => {
          that.dataList.forEach((row) => {
            if (row.scanId === item.scanId) {
              isAdd = false;
            }
          });
          if (isAdd) {
            let marker = that.writeMarker(item);
            item.marker = marker;
          }
        });

        that.userCount = res.data.sumLifeUser;
        that.callPoliceCount = 0;
        that.accessCount = 0;
        res.data.mapList.forEach((item) => {
          if (item.is_access === 1) {
            that.accessCount = item.warn_count;
          }
          that.callPoliceCount += item.warn_count;
        });
      });
    },
    getScanTimeList() {
      let that = this;
      this.$api.lifescan
        .getTimeScan({ timeType: this.dataType })
        .then((res) => {
          let datas = [];
          let values = [];
          for (let i = res.data.timeList.length - 1; i > -1; i--) {
            let item = res.data.timeList[i];
            datas.push(item);
            let value = 0;
            res.data.sumMapList.forEach((row) => {
              if (this.dataType != 3) {
                if (row.days.substr(row.days.length - 2) == item) {
                  value = row.scan_count;
                }
              } else {
                if (row.days == item) {
                  value = row.scan_count;
                }
              }
            });
            values.push(value);
          }
          this.$nextTick(() => {
            that.$refs.barChartTo.initChart(datas, values);
          });
        });
    },
    formColse() {
      if (this.htmlMarker) this.map.remove(this.htmlMarker);
      this.htmlMarker = null;
    },
    showAge(value) {
      // 指定日期
      let birthdays = new Date(value.replace(/-/g, "/"));
      // 当前系统日期
      let d = new Date();
      let age =
        d.getFullYear() -
        birthdays.getFullYear() -
        (d.getMonth() < birthdays.getMonth() ||
        (d.getMonth() == birthdays.getMonth() &&
          d.getDate() < birthdays.getDate())
          ? 1
          : 0);
      return age;
    },

    markerClick(e) {
      let that = this;
      let id = e.target.getExtData();
      that.dataList.forEach((item) => {
        if (item.scanId === id) {
          if (item.name == null || item.name == '') {
            item.name = "无名氏"
          }
          if (item.idcard == null || item.idcard == '') {
            item.idcard = "无"
          }
          that.setinfoWindow(item);
        }
      });
    },
    accessUserClick(id) {
      this.$api.lifescan.updScanAccess(id).then((res) => {
        if (res.data.code === 200) {
          this.dataList.forEach((item) => {
            if (item.scanId === id) {
              item.isAccess = 1;
              this.setinfoWindow(item);
              if (item.marker) {
                item.marker.setIcon(require("../../assets/images/marker.png"));
              }
            }
          });
        }
      });
    },
    distributeSMS() {
      console.log("================distributeSMS================");
    //   const checkboxes = document.querySelectorAll('#checkboxGroup input[type="checkbox"]:checked');
    // 创建一个数组来存储所有的 Promise
const promises = [];

this.maskList.forEach(item => {
  if (item.isSelected) {
    console.log("item.isSelected" + item.isSelected); // 打印 DOM 元素
    this.form.scanId = this.scanId;
    this.form.phone = item.phone;

    // 将 API 请求的 Promise 添加到 promises 数组中
    const promise = this.$api.lifescan.distribute(this.form).then((res) => {
      if (res.data.code === 200) {
        // 请求成功的处理逻辑
      }
      return res; // 返回响应，以便在 Promise.all 中可以处理
    });

    promises.push(promise); // 将 Promise 添加到数组中
  }
});

// 使用 Promise.all 等待所有的请求完成
Promise.all(promises).then((results) => {
  // 所有请求都完成后，可以在这里设置状态值
  console.log('所有请求已完成', results);
  this.isMask = false; // 设置状态值
}).catch((error) => {
  // 处理请求中的错误
  console.error('请求发生错误', error);
  this.isMask = false; // 设置状态值
});

    //   checkboxes.forEach(checkbox => {
    //     console.log("checkbox"+ checkbox.value); // 打印 DOM 元素
    //     this.form.scanId = this.scanId;
    //     this.form.phone = checkbox.value;
    //     this.$api.lifescan.distribute(this.form).then((res) => {
    //       if (res.data.code === 200) {
    //         // alert("发送成功");
    //       }
    //     });
    // });
    console.log("================distributeSMS= end===============");


      this.distributePhone = document.getElementById("inputValueID").value;
      if (this.distributePhone != null) {
        this.form.scanId = this.scanId;
        this.form.phone = this.distributePhone;
        this.$api.lifescan.distribute(this.form).then((res) => {
          if (res.data.code === 200) {
            // alert("发送成功");
          }
        });
      }
      let item = null;
      this.dataList.forEach((data) => {
        if (data.scanId === this.scanId) {
          item = data;
        }
      });
      this.setinfoWindow(item);
      if (item.marker) {
        item.marker.setIcon(require("../../assets/images/marker.png"));
      }
    
      // const selectedValues = Array.from(checkboxes).map(checkbox => checkbox.value);
      
      
      // console.log("selectedValues: " + selectedValues);
      // checkboxes.forEach(checkbox => {
      //   console.log("checkboxes: " + checkbox);          
      //  });
    },
    setinfoWindow(item) {
      if (this.htmlMarker) this.map.remove(this.htmlMarker);
      let that = this;
      let width = document.documentElement.clientWidth;

      that.map.setCenter(
        new window.AMap.LngLat(item.longitude, item.latitude + 0.0005)
      );
      let age = 0;
      if (item.age) age = that.showAge(item.age);

      //刷新当前位置
      let flushCurrentLocaltionHtml =
        "<div class='c-btn' onclick='flushLocation(" +
        item.scanId +
        ")'>刷新当前位置</div>";

      //是否接入
      let isAccessHtml = "<div class='r-btn t-btn'>已接入</div>";
      if (item.isAccess === 0) {
        isAccessHtml =
          "<div class='r-btn' onclick='accessUserClick(" +
          item.scanId +
          ")'>接入</div>";
      } else if (item.status === 3) {
        isAccessHtml = "<div class='r-btn t-btn'>已取消报警</div>";
      }

      //派发
      let distributeHtml =
        "<div class='d-btn di-btn' onclick='handleDistribute(" +
        item.scanId +
        ")'>派发</div>";
      if (item.isDistribute === 1) {
        distributeHtml =
          "<div class='d-btn di2-btn' onclick='handleDistribute(" +
          item.scanId +
          ")'>派发</div>";
      }

      let html =
        "<div class='map-form'>" +
        "<div class='top'>" +
        "<div class='colse' onclick='formColse()'></div>" +
        "<img class='head-img' src=" +
        item.icon +
        " />" +
        "<div class='text l t'>" +
        item.name +
        "&nbsp;&nbsp;&nbsp;&nbsp;" +
        (item.gender === 0 ? "女" : "男") +
        "&nbsp;&nbsp;&nbsp;&nbsp;" +
        age +
        "岁</div>" +
        "<div class='text l t'>手机：" +
        item.phone +
        "</div>" +
        "<div class='text l t'>身份证：" +
        item.idcard +
        "</div>" +
        "</div>" +
        "<div class='text l w'>时间：" +
        item.createTime +
        "</div>" +
        "<div class='text l w'>住址：" +
        (item.nowAddress === null ? "" : item.nowAddress) +
        "</div>" +
        // "<div class='text l w'>报警位置：" +
        // (item.alarmPosition === null || item.alarmPosition === undefined ? "" : item.alarmPosition) +
        // "</div>" +
        "<div class='text l w'>当前位置：" +
        (item.address === null ? "" : item.address) +
        "</div>";
      if (item.sim !== null) {
        html =
          html +
          "<div class='text l s'>报警电话：" +
          (item.sim === null ? "" : item.sim) +
          "</div>";
      }
      if (item.siteAddress !== null) {
        html =
          html +
          "<div class='text l s'>现场地址：" +
          (item.siteAddress === null ? "" : item.siteAddress) +
          "</div>";
      }
      if (item.preliminaryJudge !== null) {
        html =
          html +
          "<div class='text l s'>初步判断：" +
          (item.preliminaryJudge === null ? "" : item.preliminaryJudge) +
          "</div>";
      }
      if (item.contactNumber !== null) {
        html =
          html +
          "<div class='text l s'>报警电话：" +
          (item.contactNumber === null ? "" : item.contactNumber) +
          "</div>";
      }
      // if (item.requirement !== null) {
      //   html =
      //     html +
      //     "<div class='text l s'>特殊要求：" +
      //     (item.requirement === null ? "" : item.requirement) +
      //     "</div>";
      // }
      if (item.isStretcher === 1) {
        html =
          html +
          "<div class='text l s'>是否需要担架：" +
          (item.isStretcher === 0 ? "否" : "是") +
          "</div>";
      }
      if (item.loveRescuePhone !== null) {
        html =
          html +
          "<div class='text l s'>援助电话：" +
          (item.loveRescuePhone === null ? "" : item.loveRescuePhone) +
          "</div>";
      }
      if (item.remarks !== null) {
        html =
          html +
          "<div class='text l s'>备注：" +
          (item.remarks === null ? "" : item.remarks) +
          "</div>";
      }
      html =
        html +
        "<br/>" +
        flushCurrentLocaltionHtml +
        isAccessHtml +
        distributeHtml +
        "</div>";
      this.htmlMarker = new window.AMap.Marker({
        position: new window.AMap.LngLat(item.longitude, item.latitude),
        offset: new window.AMap.Pixel(
          -width * 0.1055 - 85,
          -width * 0.18 - 220
        ),
        content: html, // 添加 Icon 图标 URL
        title: item.address,
        id: "htmlMarker" + item.scanId,
      });
      this.map.add(this.htmlMarker);
    },
    handleDistribute(id) {
        console.log(id)
        this.isMask = true
      this.scanId = id;
      if (this.htmlMarker) this.map.remove(this.htmlMarker);
      let item = null;
      this.dataList.forEach((data) => {
        if (data.scanId === id) {
          item = data;
        }
      });
      let width = document.documentElement.clientWidth;
      this.map.setCenter(
        new window.AMap.LngLat(item.longitude, item.latitude + 0.0005)
      );
      this.fetchOptions(item.longitude,item.latitude);

    //     let html =
    //     "<div class='map-form'>" +
    //     "<div class='top'>" +
    //     "<div class='colse' onclick='formColse()'></div>" +
    //     "</div>" +
    //     "<div class='sms'>" +
    //     // "<span class='phone-text'>电话号码：</span>" +
    //     "<span class='phone-text'>派发：</span>" +
    //     "<div><input id='inputValueID' class='input-text' type='text' /></div>" +
    //     "</div>" +
    //     "<div class='checkbox-group' id='checkboxGroup'></div>"+


    //     "<div class='r-btn d-btn2' onclick='distributeSMS()'>发送</div>" +
    //     "</div>";

    //   this.htmlMarker = new window.AMap.Marker({
    //     position: new window.AMap.LngLat(item.longitude, item.latitude),
    //     offset: new window.AMap.Pixel(
    //       -width * 0.1055 - 85,
    //       -width * 0.18 - 220
    //     ),
    //     content: html, // 添加 Icon 图标 URL
    //     title: item.address,
    //     id: "htmlMarker" + item.scanId,
    //   });
    //   this.map.add(this.htmlMarker);
    },

    dataSelect(item) {
      this.dataType = item;
      this.getScanTimeList(item);
      let that = this;
    },
    login_out() {
      /*返回登录页*/
      this.$router.replace({
        path: "/login",
      });
    },
    flushLocation(id) {
      let that = this;
      this.$api.lifescan.getCurrentLocation(id).then((res) => {
        if (res.data.code === 200) {
          let item = null;
          this.dataList.forEach((data) => {
            if (data.scanId === id) {
              item = data;
            }
          });
          this.setinfoWindow(item);
          that.$message.success("当前位置刷新成功");
        }
      });
    },
    // 获取数据并生成复选框
    fetchOptions(lng,lat) {
            try {
              console.log("参数: "+ lng  + "       " +lat)
                
                this.$api.lifescan.rescueMembers({ lng: lng, lat: lat}).then((res) => {
                if (res.data.code === 200) {
                    this.maskList = res.data.data.map(item=>{
                        return{
                            ...item,
                            isSelected: false
                        
                        }
                    })
                    console.log(res.data.data)
                //   const checkboxGroup = document.getElementById('checkboxGroup');
                //   checkboxGroup.innerHTML = ''; // 清空之前的内容
                //   res.data.data.forEach(option => {
                //     console.log(option);
                //     const label = document.createElement('label');
                //     label.className = 'checkbox-item';
                //     label.innerHTML = `<input type="checkbox" value="${option.phone}"> ${option.name} ${option.phone}     距离: ${option.distance}km`;
                //     checkboxGroup.appendChild(label);
                // });
                }
              });
            } catch (error) {
                console.error('获取选项失败:', error);
            }
    },
    submitSelections() {  
      const checkboxes = document.querySelectorAll('input[type="checkbox"]:checked');

      const selectedValues = Array.from(checkboxes).map(checkbox => checkbox.value);

      console.log(selectedValues)
    }
  },
};
</script>

<style lang="less" scoped>
/deep/.amap-icon {
  img {
    margin-left: -26px;
  }
}

/deep/.map-form {
  .r-btn {
    // width: 4vw;
    // height: 2vw;
    width: 5vw;
    height: 2vw;
    line-height: 2vw;
    background: #289e62;
    border-radius: 0.4vw;
    position: absolute;
    margin-top: 18vw;
    margin-left: 20vw;
    cursor: pointer;
    font-size: 0.8vw;
    color: #fff;
  }

  .c-btn {
    width: 6vw;
    height: 2vw;
    line-height: 2vw;
    background: #289e62;
    border-radius: 0.4vw;
    position: absolute;
    margin-top: 18vw;
    margin-left: 13vw;
    cursor: pointer;
    font-size: 0.8vw;
    color: #fff;
  }

  .d-btn {
    width: 4vw;
    height: 2vw;
    line-height: 2vw;
    background: #0865cf;
    border-radius: 0.4vw;
    position: absolute;
    margin-top: 18vw;
    margin-left: 26vw;
    cursor: pointer;
    font-size: 0.8vw;
    color: #fff;
  }

  .d-btn2 {
    width: 4vw;
    height: 2vw;
    line-height: 2vw;
    background: #0865cf;
    border-radius: 0.4vw;
    position: absolute;
    margin-top: 2.5vw;
    margin-left: 24vw;
    cursor: pointer;
    font-size: 0.8vw;
    color: #fff;
  }

  .t-btn {
    background: #999;
  }

  .di-btn {
    background: #0865cf;
  }

  .di2-btn {
    background: #999;
  }

  .colse {
    position: absolute;
    width: 1.5vw;
    height: 1.5vw;
    margin-top: -1.5vw;
    margin-left: 34vw;
    cursor: pointer;
    background-image: url("../../assets/images/colse.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 999;
  }

  // position: absolute;
  // top: 20vw;
  // left: 40vw;
  z-index: 999;
  width: 35vw;
  height: 25vw;
  background-image: url("../../assets/images/indexform.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding-top: 2.2vw;
  padding-left: 0.9vw;
  padding-right: 0vw;

  .head-img {
    float: left;
    width: 3.5vw;
    height: 3.5vw;
  }

  .sms {
    height: 5.8vw;
    width: 55%;
    float: left;
  }

  .top {
    height: 5.8vw;
    width: 100%;
    float: left;
  }

  .phone-text {
    float: left;
    color: white;
    font-size: 0.9vw;
    font-family: Source Han Sans CN;
    // margin-top: 1.1vw;
    margin-top: 3vw;
    margin-left: 3vw;
    // left: 1vw;
  }

  .input-text {
    float: left;
    height: 1.5vw;
    width: 60%;
    margin-top: 2.8vw;
    color: white;
    font-size: 0.9vw;
    background: #042d55;
    // border-radius: 5px 5px 5px 5px;
    border-radius: 22px 22px 22px 22px;
  }

  .text {
    float: left;
    color: white;
    font-size: 0.9vw;
    font-family: Source Han Sans CN;
    margin-top: 1.1vw;
  }

  .text-1 {
    float: left;
    color: white;
    font-size: 0.9vw;
    font-family: Source Han Sans CN;
    margin-top: 1.1vw;
  }

  .w {
    width: 90%;
    text-align: left;
  }

  .f {
    font-size: 0.7vw;
  }

  .l {
    margin-left: 0.7vw;
  }

  .s {
    width: 153px;
    height: 17px;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    text-align: left;
  }

  .t {
    margin-top: 0;
  }

  .r-btn {
  }




//todo ===============================
.checkbox-group {
// display: flex;
flex-direction: column; /* 列式排布 */
}

.checkbox-item {
border-radius: 8px; /* 设置圆角 */
font-size: 10px;
color: #7FC9BC;
padding: 10px; /* 内边距 */
// background-color: #f5f5f5; /* 背景色 */
margin-left: 60%;
display: flex;
align-items: center;
cursor: pointer;
}

.checkbox-item input[type="checkbox"] {
margin-right: 10px; /* 复选框与文本之间的间距 */
}

.checkbox-item:hover {
// background-color: #e0e0e0; /* 悬停效果 */
}

}

.a-marker {
  margin-right: 50px;
}

.index {
  height: 100vh;
  width: 100vw;
  background-color: #0a0b0c;

  .head {
    height: 6.9vw;
    width: 100%;
    float: left;
    background-image: url("../../assets/images/indexhead.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    label {
      width: 100%;
      float: left;
      height: 3.6vw;
      line-height: 3.6vw;
      font-size: 1.8vw;
      letter-spacing: 0.5vw;
      font-weight: bold;
      text-align: center;
      font-family: FZLanTingHeiS-DB-GB;
      color: #fff;
      text-shadow: 0px 0.2vw 0.5vw rgba(3, 26, 44, 0.48);
    }

    .time {
      position: absolute;
      color: #00fcff;
      top: 0.7vw;
      right: 5vw;
      font-size: 0.6vw;
      height: 0.6vw;
      line-height: 0.6vw;
      font-family: Source Han Sans CN;
    }

    .loginOut {
      position: absolute;
      color: #ff0000;
      top: 0.8vw;
      right: 0.8vw;
      font-size: 1vw;
      height: 1vw;
      line-height: 0.6vw;
      font-family: KaiTi_GB2312;
    }
  }

  .right {
    float: left;
    box-sizing: border-box;
    width: 77.5vw;
    height: calc(100vh - 6.9vw);
  }

  .left {
    float: left;
    font-family: Source Han Sans CN;
    width: 22.5vw;

    .head {
      width: 22.5vw;
      height: 0.9vw;
      float: left;
      background-image: url("../../assets/images/index_line.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-top: -0.9vw;

      .text {
        position: absolute;
        color: #fff;
        text-align: left;
        font-size: 1.1vw;
        margin-top: -1vw;
        margin-left: 0.2vw;
      }
    }

    .number {
      float: left;
      height: 5.2vw;
      width: 100%;
      margin-top: 2vw;

      .item {
        float: left;
        height: 5.2vw;
        width: 4.2vw;
        margin-left: 3vw;

        .img {
          float: left;
          height: 3.5vw;
          width: 4.2vw;
          background-image: url("../../assets/images/index_number.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;

          .num {
            text-align: center;
            height: 3.5vw;
            line-height: 3.5vw;
            font-size: 1.2vw;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            background: linear-gradient(-18deg, #03bbff 0%, #4df5b0 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .text {
          float: left;
          margin-top: 0.7vw;
          width: 4.2vw;
          text-align: center;
          height: 0.8vw;
          font-size: 0.8vw;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }

    .datatype {
      float: left;
      height: 1.7vw;
      margin-top: 2vw;
      width: 100%;

      .select {
        border: 1px solid #03bff2;
        border-radius: 0.9vw;
      }

      div {
        border: 1px solid #0a0b0c;
        cursor: pointer;
        width: 3.5vw;
        margin-left: 1vw;
        margin-right: 1vw;
        height: 1.7vw;
        line-height: 1.7vw;
        color: #fff;
        font-size: 0.9vw;
        float: left;
      }
    }

    .echart-img {
      float: left;
      margin-top: 2vw;
      width: 22.5vw;
      height: 12vw;

      .back {
        position: absolute;
        width: 22.5vw;
        height: 3.9vw;
        margin-top: -2.5vw;
        background-image: url("../../assets/images/back-echart-img.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }

    .record {
      float: left;
      margin-top: 0.5vw;
      width: 22.5vw;
      height: calc(100vh - 35vw);
      overflow-y: auto;
      overflow-x: hidden;

      .row {
        float: left;
        margin-top: 0.6vw;
        width: 22.3vw;
        height: 1.8vw;
        cursor: pointer;

        .text {
          width: 20.7vw;
          text-align: left;
          padding-left: 0.5vw;
          box-sizing: border-box;
          float: left;
          font-size: 0.7vw;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          height: 1.8vw;
          line-height: 1.8vw;
          background: #001728;
        }

        .num {
          width: 1.6vw;
          float: left;
          font-size: 0.7vw;
          font-family: Source Han Sans CN;
          font-weight: 400;
          background-color: #002a48;
          color: #ffffff;
          height: 1.8vw;
          line-height: 1.8vw;
        }
      }
    }
  }
}

.head-1 {
  background-image: url("../../assets/images/back-echart-img.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: 1vw;
  float: left;
  height: 1.7vw;
  width: 100%;

  .select {
    border: 1px solid #03bff2;
    width: 6vw;
    border-radius: 0.9vw;
  }

  div {
    cursor: pointer;
    width: 5vw;
    margin-left: 1vw;
    margin-right: 1vw;
    height: 1.7vw;
    line-height: 1.7vw;
    color: #fff;
    font-size: 0.8vw;
    float: left;
    margin-top: -0.9vw;
  }
}

/*修改滚动条样式*/
.record::-webkit-scrollbar {
  width: 6px;
  height: 10px;
  /**/
}

.record::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}

.record::-webkit-scrollbar-thumb {
  background: #4fc5c7;
  border-radius: 10px;
}

.record::-webkit-scrollbar-thumb:hover {
  background: #42a5a7;
}

.record::-webkit-scrollbar-corner {
  background: #179a16;
}

#currentlocation {
}
.map-form {
}
.mask{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
   display: flex;
   align-items: center;
   justify-content: center;

}
.mask_bg{
    width: 862px;
    height: 667px;
    background: url("../../assets/images/mask.png") no-repeat center center;
    background-size: 100% 100%;
    position: relative;
}
.mask_title{
    font-size: 24px;
    color: #B4CDFF;
    line-height: 10px;
}
.mask-content{
    width: 100%;
    // padding: 0 5%;
    box-sizing: border-box;
    margin: 50px auto;
    // border: 1px solid #B4CDFF;
    color: #fff;
}
.mask-content>div:first-child,.list-item{
    display: flex;
    height: 40px;
    align-items: center;
    font-size: 20px;
    padding: 0 5%;
    

}
.mask-content>div>div,.list-item>div{
    flex: 1;
    
}
.list-item{
    font-size: 16px !important;
    
}
.list-item:nth-child(even){
    background: rgba(16, 68, 121, 0.2);
}
.list-item:nth-child(odd){
    background: rgba(16, 68, 121, 0.1);
}
.list{
    height: 400px;overflow-y: auto;
 
  
}

   // 修改滚动条样式宽1px 白色
   ::-webkit-scrollbar {
        width: 0px;
        height: 10px;
        background-color: #fff;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #B4CDFF;
        border-radius: 10px;
    }
    .mask-footer{
        width: 90%;
        margin: auto;
        display: flex;
        justify-content: space-between;
    }
    .mask-footer>div:first-child{
        width: 120px;
        height: 48px;
        border-radius: 12px 12px 12px 12px;
        background: #04379e;
        line-height: 48px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
    }
    .mask-footer>div:last-child{
        width: 120px;
        height: 48px;
        border-radius: 12px 12px 12px 12px;
        background: #289e62;
        line-height: 48px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
    }
</style>
