<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
import resize from './mixins/resize'

const animationDuration = 6000

export default {
  mixins: [resize],
  props: {

    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '22.5vw'
    },
    height: {
      type: String,
      default: '11vw'
    }
  },
  data() {
    return {
      chart: null
    }
  },
  mounted() {
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart(title, value) {
      let that = this;
      this.chart = echarts.init(this.$el, 'macarons')
      this.chart.setOption({
        title: { show: false, },
        backgroundColor: 'rgba(10, 11, 12, 1)',
        xAxis: {
          boundaryGap: false, // 显示间距设置为false
          type: 'category',
          data: title,
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba0, 252, 255,0.2)']
            }
          },
          axisLabel: {//x轴文字的配置
            show: true,
            margin: 20, //刻度标签与轴线之间的距离 
            textStyle: {
              color: "#fff",
            }
          }
        },
        grid: {
          top: 10,
          bottom: 40
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(0, 252, 255,0.2)']
            }
          },
          axisLabel: {//x轴文字的配置
            show: true,
            textStyle: {
              color: "#fff",
            }
          }
        },
        series: [
          {
            data: value,
            type: 'line',
            areaStyle: {
              opacity: 0.5,
              // 填充的颜色-渐变
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(0, 252, 255, 1)'
              }, {
                offset: 1,
                color: 'rgba(1, 2, 3)'
              }])
            },
            itemStyle: {
              normal: {
                color: '#00FCFF', //改变折线点的颜色
                lineStyle: {
                  color: '#00FCFF' //改变折线颜色
                }
              }
            }
          }
        ]

      })
    }
  }
}
</script>
